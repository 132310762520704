import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import BlogPost from '../components/molecules/BlogPost'

import styles from './blog.module.scss'

const Blog = ({ data }) => {
  const posts = data.posts.edges

  return (
    <div>
      <div className={styles.posts}>
        {posts.map(({ node }) => {
          let { frontmatter } = node
          const { slug, title, cover, excerpt } = frontmatter

          return (
            <article className={styles.post} key={slug}>
              <Link to={'/posts/' + slug}>
                <BlogPost fluid={cover.childImageSharp.fluid} alt={title} description={excerpt}/>
              </Link>
            </article>
          )
        })}
      </div>
    </div>
  )
}

Blog.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
}

export default Blog

export const IndexQuery = graphql`
  query {
    posts: allMarkdownRemark {
      edges {
        node {
          frontmatter {
            slug
            cover {
              childImageSharp {
                fluid(maxWidth: 980, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            excerpt
          }
        }
      }
    }
  }
`
